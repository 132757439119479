<template>
  <div style="width: 100%">
    <v-col cols="12" class="mt-0">
      <p class="red--text m-0">* Hãy nhập các option và chọn đáp án đúng</p>
      <v-radio-group v-model="correctSingleSelect">
        <template v-for="(option, i) in optionAnswerSingleSelect">
          <div :key="i" class="d-flex">
            <p class="text-h5 mt-6 mr-2" style="color: #6495ed">
              {{ i | getAlphabetCharacterFromIndex }}.
            </p>
            <v-radio :value="option">
              <template v-slot:label>
                <v-text-field
                  :label="'Option' + (i + 1)"
                  :value="option"
                  @input="changeValueSingleSelect($event, i)"
                ></v-text-field>
                <v-btn class="mb-2" @click="deleteOptionSingleSelect(i)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-radio>
          </div>
        </template>
      </v-radio-group>
    </v-col>
    <v-col cols="3">
      <v-btn large @click="addOptionSingleSelect">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "SingleSelect",
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    correct: {},
  },
  computed: {
    optionAnswerSingleSelect: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
    correctSingleSelect: {
      get() {
        return this.correct;
      },
      set(value) {
        this.$emit("setCorrectAnswer", value);
      },
    },
  },
  methods: {
    changeValueSingleSelect(event, i) {
      this.correctSingleSelect = event;
      this.optionAnswerSingleSelect.splice(i, 1, event);
    },
    deleteOptionSingleSelect(i) {
      if (this.optionAnswerSingleSelect.length <= 1) {
        this.$toasted.error("Phải có tối thiểu 1 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.optionAnswerSingleSelect.splice(i, 1);
    },
    addOptionSingleSelect() {
      let data = "";
      this.optionAnswerSingleSelect.push(data);
    },
  },
};
</script>

<style scoped></style>
